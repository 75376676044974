import { createGlobalStyle } from 'styled-components';
import 'typeface-montserrat';
import colors from './colors';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    line-height: 1.9;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
  }

  body{
    background: ${colors.black};
    background-size: 40vh;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    font-family: 'Montserrat';
    font-weight: 300;
  }

  body, input, button, a{
    font-size: 14px;
    color:${colors.white};
    list-style-type: none;
    text-decoration: none;
  }

  #root{
    margin: 0 auto;
  }
`;
