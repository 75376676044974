import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaSpotify,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';

import { Container } from './styles';

const SocialNetworking: React.FC = () => (
  <Container>
    <a
      href="https://www.facebook.com/ricardowagneroficial"
      target="_Texto nk"
      rel="noreferrer"
    >
      <FaFacebookF />
    </a>
    <a
      href="https://www.instagram.com/ricardowagneroficial/"
      target="_Texto nk"
      rel="noreferrer"
    >
      <FaInstagram />
    </a>
    <a
      href="https://www.youtube.com/c/canaltvapostolica"
      target="_Texto nk"
      rel="noreferrer"
    >
      <FaYoutube />
    </a>
    <a
      href="https://bit.ly/whatsappricardowagner"
      target="_Texto nk"
      rel="noreferrer"
    >
      <FaWhatsapp />
    </a>
    <a
      href="https://open.spotify.com/show/49MXPBmPwz4F3n2NRcEJXT"
      target="_Texto nk"
      rel="noreferrer"
    >
      <FaSpotify />
    </a>
  </Container>
);
export default SocialNetworking;
