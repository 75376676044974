import React from 'react';

import { Link } from 'react-router-dom';
import SocialNetworking from '../../components/SocialNetworking';
import rwicon from '../../assets/rw-icon.png';
import { Container, By } from './styles';

import is from '../../assets/logo-is.png';

const Footer: React.FC = () => (
  <Container>
    <Link to="/">
      <img src={rwicon} alt="Ícone Ricardo Wagner" className="logo-rw" />
    </Link>
    <SocialNetworking />
    <p>Contatos</p>
    <a href="ricardowagneroficial@gmail.com">ricardowagneroficial@gmail.com</a>
    <a href="https://infosulst.com.br" target="_blank" rel="noreferrer">
      <By>
        Desenvolvido por
        <img src={is} alt="Logo IS - Smart Solutions" />
      </By>
    </a>
  </Container>
);
export default Footer;
