import styled from 'styled-components';

import { buttonStyles } from '../../styles/button';
import colors from '../../styles/colors';

export const Container = styled.button`
  ${buttonStyles}

  a {
    text-decoration: none;

    display: flex;
    align-items: center;

    &:link {
      color: ${colors.white};
    }
    &:visited {
      color: ${colors.white};
    }
  }
`;
