import styled from 'styled-components';
import colors from '../../styles/colors';

export const Titulo = styled.div`
  margin: 10vh 0 5vh 5vw;
  font-size: 3.2rem;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;

  div {
    background-color: ${colors.green_1};
    width: 40%;
    height: 10px;
    margin: -20px 15px 0 0;
  }
  div {
    margin: -10px 15px 0 0;
  }

  @media only screen and (max-width: 785px) {
    margin: 4.3rem 2rem;
    font-size: 2rem;
  }
`;

export const SubTitulo = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1rem 0;
  text-transform: uppercase;
  width: 100%;

  div {
    background-color: ${colors.yellow_1};
    width: 3rem;
    height: 2px;
    margin: 0 15px 0 0;
  }

  @media only screen and (max-width: 785px) {
    font-size: 1.4rem;
  }
`;

export const Home = styled.div`
  background: linear-gradient(0.49deg, #000000 11.5%, rgb(255 255 255) 711.99%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;

  img:nth-child(2n) {
    margin: 3rem 0;
  }

  img:nth-child(3n) {
    height: 80%;
  }

  blockquote {
    margin: 3vh 10vh 3vh 0;
  }

  @media only screen and (min-width: 785px) {
    margin-bottom: 10vh;

    blockquote {
      width: 30rem;
    }
  }

  @media only screen and (max-width: 785px) {
    & > div {
      margin: 5vh 2vw;
    }

    img:nth-child(3n) {
      width: 80%;
    }
  }
`;

export const BotaoArrasta = styled.div`
  height: 20px;
  display: block;
  font-weight: 400;

  a {
    color: ${colors.white};
    text-decoration: none;

    svg {
      font-size: 1.3rem;
      color: ${colors.yellow_1};
      margin-bottom: -5px;
    }

    &:hover {
      color: ${colors.yellow_1};
      svg {
        font-size: 2rem;
        margin-bottom: -10px;
      }
    }
  }
`;

export const Quote = styled.div`
  display: flex;
  p {
  }
  div {
    background-color: ${colors.yellow_1};
    width: 2rem;
    height: 0.5rem;
    margin: 5px 15px 0 0;
  }
`;

export const Sobre = styled.div`
  & > div:nth-child(2n) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }

  svg {
    font-size: 3rem;
    color: ${colors.green_1};
    margin-top: 8rem;
  }

  @media only screen and (max-width: 785px) {
    & > div:nth-child(2n) {
      padding: 0;
    }
  }
`;

export const InformacoesSobre = styled.div`
  background-color: ${colors.gray_1};
  padding: 5vh 3vh;
  border-radius: 0.5vh;
  width: 42vh;

  @media only screen and (min-width: 785px) {
    width: 23vw;
    height: 100%;
  }
`;

export const Valores = styled.div`
  & > div:nth-child(2n) {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    ul {
      width: 40vw;
      list-style-type: none;

      li {
        font-size: 2rem;
        font-weight: 500;

        svg {
          color: yellow;
          margin-right: 1rem;
        }

        p {
          width: 30vw;
          font-weight: 100;
          text-align: justify;
        }
      }
    }

    img {
      width: 45vh;
      margin: 3rem 0;
    }
  }

  @media only screen and (max-width: 785px) {
    ul {
      width: 80vw !important;
      text-align: center;

      li {
        padding: 2rem 0;

        p {
          width: 100% !important;
        }
      }
    }
  }
`;

export const Idealizador = styled.div`
  & > div:nth-child(2n) {
    display: flex;
  }

  svg {
    font-size: 3rem;
    color: ${colors.green_1};
    margin-top: 13vh;
  }

  @media only screen and (max-width: 785px) {
    & > div:nth-child(2n) {
      padding: 0;
      flex-direction: column;
    }
  }
`;

export const Idealizado = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (min-width: 785px) {
    & > div {
      width: 60%;
      padding: 0 5vw;
    }

    img {
      max-width: 40%;
    }
  }

  @media only screen and (max-width: 785px) {
    flex-direction: column;
    margin: 0;
    padding: 0;

    img {
      margin: 0;
      max-width: 95%;
    }
  }
`;

export const Obras = styled.div`
  & > div:nth-child(2n) {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
  }

  @media only screen and (max-width: 785px) {
    & > div:nth-child(2n) {
      flex-direction: column;
    }
  }
`;

export const Obra = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.gray_1};
  padding: 2vh;
  border-radius: 0.5vh;
  margin: 5vh 2vh 0 2vh;

  img {
    width: 15vh;
    margin-top: -7vh;
  }

  & > p:nth-child(1n) {
    height: 100%;
    padding-bottom: 1rem;
  }

  button {
    margin-bottom: -2.4rem;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 785px) {
    &:nth-child(n + 2) {
      margin: 15vh 2vh 0 2vh;
    }
  }

  @media only screen and (min-width: 785px) {
    max-width: 25vw;
  }
`;

export const Biografia = styled.div`
  & > div:nth-child(2n) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
  }

  img {
    max-width: 40%;
  }

  svg {
    font-size: 3rem;
    color: ${colors.green_1};
    margin-top: 8rem;
  }

  @media only screen and (min-width: 785px) {
    & > div:nth-child(2n) {
      div {
        max-width: 40%;
      }
    }
  }

  @media only screen and (max-width: 785px) {
    img {
      max-width: 90%;
    }

    & > div:nth-child(2n) {
      padding: 0;
    }
  }
`;
