import styled from 'styled-components';
import colors from '../../styles/colors';

export const Head = styled.section`
  transition: all 200ms ease 0s;
  width: 100%;
  padding: 1.7rem 0;

  img {
    width: 7rem;
  }

  .logo-rw {
    width: 200px;
  }

  @media only screen and (max-width: 785px) {
    nav .navigation-menu.expanded {
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      height: 100vh;
      background: black;
      z-index: 2;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12vh;

        li {
          a {
            text-decoration: none;
            color: ${colors.white};
            font-size: 5vh;
            font-weight: 600;

            &:hover {
              color: ${colors.yellow_1};
            }
          }
        }
      }
    }

    .hamburger {
      position: fixed;
      color: ${colors.white};
      top: 7vh;
      right: 2vw;
      background: none;
      border: none;
      z-index: 3;

      svg {
        font-size: 2.75rem;
      }
    }

    .navigation-menu ul {
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: ${colors.black};
    }

    .navigation-menu li {
      text-align: center;
      margin: 0;
      background-color: ${colors.black};
    }

    .navigation-menu ul {
      display: none;
    }

    .navigation-menu.expanded ul {
      display: block;
    }

    .navigation-menu.expanded ul {
      display: block;
    }

    .navigation-menu.expanded {
      background-color: red;
    }
  }

  @media only screen and (min-width: 785px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-direction: row;

    nav {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin-top: 30px;

        li {
          color: white;
          list-style-type: none;
          margin: 10px;
          color: white;
          padding: 14px 16px;
          text-decoration: none;
          font-size: 17px;

          a {
            text-decoration: none;
            color: ${colors.white};
            font-weight: 500;

            &:hover {
              padding-bottom: 25px;
              border-bottom: 3px solid ${colors.green_1};
              color: ${colors.green_1};
            }
          }
        }
      }

      .hamburger {
        display: none;
      }
    }
  }
`;
