import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  svg {
    color: ${colors.black};
    background-color: ${colors.green_1};
    margin: 10px;
    padding: 4px;
    font-size: 1.5rem;
    border-radius: 50%;
  }

  a:hover {
    svg {
      color: ${colors.black};
      background-color: ${colors.yellow_1};
    }
  }
`;
