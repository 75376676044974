import React from 'react';

import {
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from 'react-icons/bs';

import { GiCheckMark } from 'react-icons/gi';

import { HashLink } from 'react-router-hash-link';
import { Carousel } from '@trendyol-js/react-carousel';
import Button from '../../components/Button';
import SocialNetworking from '../../components/SocialNetworking';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Home,
  BotaoArrasta,
  Quote,
  Sobre,
  Titulo,
  SubTitulo,
  InformacoesSobre,
  Valores,
  Idealizador,
  Idealizado,
  Obras,
  Obra,
  Biografia,
} from './styles';

import rwHome from '../../assets/ricardo-wagner.png';
import rwValores from '../../assets/ricardo-wagner-valores.png';
import rwBiografia from '../../assets/ricardo-wagner-biografia.png';
import rwLogo from '../../assets/rw-logo.png';

import apostolico from '../../assets/apostolico.png';
import escritor from '../../assets/escritor.png';
import politico from '../../assets/politico.png';
import palestrante from '../../assets/palestrante.png';
import empresario from '../../assets/empresario.png';

import aca from '../../assets/aca.png';
import cv from '../../assets/cv.png';
import conferencia from '../../assets/conferencia.png';
import rac from '../../assets/rac.png';

import livroOPoderDaAlianca from '../../assets/livro-o-poder-da-alianca.png';
import livroNovoPlanoVida from '../../assets/livro-novo-plano-de-vida.png';
import livroValeTaquari from '../../assets/livro-vale-do-taquari.png';

const ChevronLeft = React.createElement('a', null, <BsChevronDoubleLeft />);
const ChevronRight = React.createElement('a', null, <BsChevronDoubleRight />);

function sizeOfThings(slide = 1) {
  const largura =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return largura > 785 ? (slide === 1 ? 3.5 : 1) : 1;
}

sizeOfThings(1);
sizeOfThings(2);

window.addEventListener('resize', function atualizaQuantidade() {
  sizeOfThings(1);
  sizeOfThings(2);
});

const SiglePage: React.FC = () => (
  <>
    <Home>
      <Header />
      <div>
        <Quote>
          <div />
          <p>
            PRECISAMOS DE UM PROPÓSITO <br />
            MAIOR DO QUE NÓS E FORA DE NÓS!
          </p>
        </Quote>
        <img
          src={rwLogo}
          alt="Logo da campanha de Ricardo Wagner"
          width="300rem"
        />
        <SocialNetworking />
        <blockquote>
          Está chegando a hora de um novo tempo para o Brasil, entendi através
          do chamado de Deus que faço parte desse novo momento, me coloco a
          disposição de todos sendo pré-candidato a Deputado Federal, sou
          cristão, patriota e conservador!
        </blockquote>
        <BotaoArrasta>
          <HashLink to="#Sobre">
            ARRASTA E SAIBA MAIS <BsChevronDoubleDown />
          </HashLink>
        </BotaoArrasta>
      </div>
      <img src={rwHome} alt="Busto de Ricardo Wagner" width="400rem" />
    </Home>

    <Sobre id="Sobre">
      <Titulo>
        Sobre
        <div />
      </Titulo>
      <div>
        <Carousel
          show={sizeOfThings(1)}
          slide={1}
          transition={0.5}
          leftArrow={ChevronLeft}
          rightArrow={ChevronRight}
        >
          <InformacoesSobre>
            <img src={apostolico} alt="Apóstolo" />
            <SubTitulo>
              Apóstolo
              <div />
            </SubTitulo>
            <p>
              Apóstolo fundador e líder sênior do Centro Apostólico de Teutônia,
              que pastoreia há quase 40 anos. Exerce seu apostolado
              primariamente nas igrejas aliançadas à Rede Apostólica Cristã,
              fundada por ele, uma das primeiras Redes Apostólicas do Brasil,
              com milhares de cristãos sendo formados. Atua na formação e no
              amadurecimento dos 5 ministérios, na implantação e transição de
              igrejas no modelo apostólico, no treinamento de líderes para o
              Reino de Deus.
            </p>
          </InformacoesSobre>
          <InformacoesSobre>
            <img src={empresario} alt="Empresário" />
            <SubTitulo>
              Empresário
              <div />
            </SubTitulo>
            <p>
              Empresário do ramo coureiro como diretor e proprietário da empresa
              La Pelle Couros Ltda. Empresário do ramo de telecomunicações como
              proprietário da Empresa de Radiodifusão Teutônia Ltda. (Rádio
              Germânia). Sócio da empresa GRW Participações e WWD Participações,
              empresas que participam de empreendimentos na construção civil e
              no ramo imobiliário. Seus familiares são os criadores e
              proprietários das bebidas Fruki e da Cosntrutora ICCILA.
            </p>
          </InformacoesSobre>
          <InformacoesSobre>
            <img src={politico} alt="Político" />
            <SubTitulo>
              Político
              <div />
            </SubTitulo>
            <p>
              Atuou como Secretário de Planejamento e Mobilidade Urbana do
              município de Teutônia, onde foi responsável pelo planejamento
              estratégico do município, foi condecorado como Cidadão Emérito de
              Teutônia. Foi imposto pela presidência AMVAT como Coordenador do
              Comitê Extratégico do Vale do Taquari - CEVT, esse comitê foi
              criado como uma proposta unir as entidades e instituições
              regionais, para estabelecer um projeto futuro para o Vale do
              Taquari. Foi comissionado como presidente estadual do Movimento
              Cristão Conservador - MCC do PTB.
            </p>
          </InformacoesSobre>
          <InformacoesSobre>
            <img src={palestrante} alt="Palestrante" />
            <SubTitulo>
              Palestrante
              <div />
            </SubTitulo>
            <p>
              Através da sua história de vida consegue ajudar centenas de
              pessoas por meio de suas palestras em conferências, congressos,
              escolas e universidades. No início da pandemia começou um projeto
              de Lives chamado Conversa na Escadaria, onde foi comentarista e
              analista, essas lives buscam discutir demandas municipais,
              regionais, estaduais e federais, com diversos empresários e
              lideranças políticas do estado do RS. Já contabilizamos mais de 50
              Lives sobre assuntos diversos.
            </p>
          </InformacoesSobre>
          <InformacoesSobre>
            <img src={escritor} alt="Escritor" />
            <SubTitulo>
              Escritor
              <div />
            </SubTitulo>
            <p>
              3 livros publicados que já ajudaram milhares de pessoas. Sendo
              eles: O Poder das Alianças, Um Novo Projeto de Vida e Vale do
              Taquari, existe uma saída ela está em nós. Escreveu mais de 10
              apostilas da Academia Apostólica, foi prefaciador e orientado de
              diversas obras. É tradutor de obras literarias internacionais.
              Escreve artigos para jornais da região, como por exemplo: Jornal
              do Comèrcio, Jornal Nova Geração, A Hora, Folha Popular, além de
              revistas.
            </p>
          </InformacoesSobre>
        </Carousel>
      </div>
    </Sobre>

    <Valores id="Valores">
      <Titulo>
        Valores
        <div />
      </Titulo>
      <div>
        <ul>
          <li>
            <GiCheckMark />
            DEUS acima de tudo e de todos
            <p>
              Deus como primeiro alicerce ideológico do MCC represena a
              cosmovisãoque guia todas as premissas político governamenais do
              movimento.
            </p>
          </li>
          <li>
            <GiCheckMark />
            PÁTRIA próspera e em desenvolvimento
            <p>
              A soberania nacional é um preceio básico para a existência e
              respeio a individualidade e diversidade nacional.
            </p>
          </li>
          <li>
            <GiCheckMark />
            FAMÍLIA como base social
            <p>
              A família composta por um homem, uma mulher e seus filhos é a mais
              elementar esrutura básica da sociedade.
            </p>
          </li>
          <li>
            <GiCheckMark />
            VIDA como bem mais precioso
            <p>
              A vida é sagrada. Na cosmovisão judaico-cristã, a vida é dada por
              Deus. Assim, ningém tem o direito a tirar a vida de ningém, pois
              isto seria uma afrona àquilo que de mais valioso temos.
            </p>
          </li>
          <li>
            <GiCheckMark />
            LIBERDADE para o Brasil
            <p>
              A liberdade é a lua perpétua e contínua de cada indivíduo e nação.
              O homem nasceu em lierdade. Não é o Estado que dá lierdade a cada
              indivíduo. Ese é seu direito natural ao nascer.
            </p>
          </li>
        </ul>

        <img src={rwValores} alt="Busto de Ricardo Wagner" width="400rem" />
      </div>
    </Valores>

    <Idealizador id="Idealizador">
      <Titulo>
        Idealizador
        <div />
      </Titulo>
      <div>
        <Carousel
          show={sizeOfThings(2)}
          slide={1}
          transition={0.5}
          leftArrow={ChevronLeft}
          rightArrow={ChevronRight}
        >
          <Idealizado>
            <div>
              <SubTitulo>
                Rede Apostólica Cristã
                <div />
              </SubTitulo>
              <p>
                A Rede Apostólica Cristã é um conjunto de igrejas e ministérios
                aliançados, que se unem debaixo de uma liderança apostólica com
                a finalidade de se fortalecer, crescer e multiplicar pela
                potencialização de suas forças. Jesus disse que o Reino de Deus
                é semelhante a uma rede (Mt 13:47). Isto significa que quanto
                mais nos aproximamos do estabelecimento do Reino de Deus aqui na
                terra, mais semelhante a uma rede será a estrutura da igreja. A
                Rede Apostólica Cristã é liderada por uma equipe apostólica que
                tem como objetivo trazer visão, unção e estrutura para as
                igrejas nas cidades ajudando-as na visão de conquista de suas
                cidades para Deus.
              </p>
            </div>
            <img src={rac} alt="RAC" />
          </Idealizado>
          <Idealizado>
            <div>
              <SubTitulo>
                Conexão Vertical
                <div />
              </SubTitulo>
              <p>
                Não é simplesmente um nome escolhido ao acaso, mas sim, um nome
                que tem um significado. São dias separados pra Deus, onde nós,
                estamos disponíveis, abertos à receber tudo aquilo que Deus tem
                pra nossa vida! O direcionamento, as respostas, o renovo, os
                sonhos, o alinhamento! Conexão Vertical, é único! É o único
                encontro específico para jovens da Rede Apostólica, é muito mais
                do que um retiro anual de jovens. É o mover intenso da presença,
                da unção e da sabedoria de Deus em uma geração sedenta por muito
                mais dEle, uma geração que deseja, quer e se coloca disponível
                para que Deus realize através dela, tudo o que Ele tem planejado
                e sonhado para nós
              </p>
            </div>
            <img src={cv} alt="Conexão Vertical" />
          </Idealizado>
          <Idealizado>
            <div>
              <SubTitulo>
                Conferência de Líderes
                <div />
              </SubTitulo>
              <p>
                A conferência de líderes, reúne anualmente, centenas de líderes
                da Rede Apostólica que se alinharam cada vez mais no propósito
                de marcar as suas futuras gerações, para que assim o poder e a
                glória de Deus sempre possam estar presentes em nossas vidas.
              </p>
            </div>
            <img src={conferencia} alt="Conferência de líderes" />
          </Idealizado>
          <Idealizado>
            <div>
              <SubTitulo>
                Academia Apostólica
                <div />
              </SubTitulo>
              <p>
                A Academia Apostólica vem desbravando distâncias e fronteiras
                com o objetivo de ampliar a visão apostólica da igreja no que
                diz respeito ao seu chamado e propósito o projeto da Academia
                Apostólica tem se realizado de maneira progressiva, ampliando a
                visão e contribuindo, significativamente, com o desenvolvimento
                de pessoas dentro de uma estrutura estratégica que visa
                resultados mais expressivos dentro do propósito do Reino de
                Deus.
              </p>
            </div>
            <img src={aca} alt="ACA" />
          </Idealizado>
        </Carousel>
      </div>
    </Idealizador>

    <Obras id="Obras">
      <Titulo>
        Obras
        <div />
      </Titulo>
      <div>
        <Obra>
          <img src={livroOPoderDaAlianca} alt="Livro O Poder das alianças" />
          <SubTitulo>
            O poder das alianças
            <div />
          </SubTitulo>
          <p>
            A nossa sociedade, principalmente nossa geração, não possui o
            correto conceito e a seriedade com que Deus trata as alianças. A
            banalização das alianças tem sido uma marca dos nossos tempos. A
            leviandade com que as pessoas têm feito alianças e as têm quebrado
            mostra que nossa geração desconhece os princípios que governam as
            alianças. Uma das provas disso é o aumento do número de divórcios.
            Atualmente, no Brasil, 25% dos casamentos dão em divórcio.
          </p>
          <Button url="lead">
            <div>
              <p>Obter e-book</p>
            </div>
          </Button>
        </Obra>
        <Obra>
          <img src={livroNovoPlanoVida} alt="Livro Um Novo Plano de Vida" />
          <SubTitulo>
            Um novo projeto de vida
            <div />
          </SubTitulo>
          <p>
            Ao redor do mundo, em toda a parte de nosso planeta, o ser humano
            busca por respostas que o façam compreender o verdadeiro sentido da
            vida. O segredo da felicidade e a fórmula mágica para o sucesso são
            alvos desta busca que tem levado muitos a lugar nenhum!
          </p>
          <Button url="lead">
            <div>
              <p>Obter e-book</p>
            </div>
          </Button>
        </Obra>
        <Obra>
          <img src={livroValeTaquari} alt="Livro O Vale do Taquari" />
          <SubTitulo>
            Vale do Taquari
            <div />
          </SubTitulo>
          <p>
            Devemos nos movimentar em busca do que acreditamos ser melhor, para
            o despertar da consciência, para a construção de um novo tempo.
            Deixar velhos preconceitos e focar em novas possibilidades. Refletir
            sobre sonhos e o uso do tempo com maior critério. No aqui, no agora,
            no tempo e no espaço que temos e vivemos e, no como ou quanto
            estamos preparados para percorrer o caminho para o sucesso e
            desenvolvimento coletivo.
          </p>
          <Button url="lead">
            <div>
              <p>Obter e-book</p>
            </div>
          </Button>
        </Obra>
      </div>
    </Obras>

    <Biografia id="Biografia">
      <Titulo>
        Biografia
        <div />
      </Titulo>
      <div>
        <img src={rwBiografia} alt="Busto de Ricardo Wagner" width="400rem" />
        <div>
          <h1>Ricardo Wagner</h1>
          <p> Casado com Eneida Wagner com quem tem 3 filhos e 2 netos;⁣</p>
          <ul>
            <li>
              Apóstolo membro da Global Spheres Inc. e da International
              Coalition of Apostolic Leaders (ICAL);⁣
            </li>
            <li>
              Apóstolo Sênior e Fundador da Rede Apostólica Cristã / Rede com
              mais de 70 Igrejas Cristãs aliançadas;⁣
            </li>
            <li>
              Pastor Sênior da Comunidade Cristã de Canabarro, hoje Centro
              Apostólico, no qual exerce seu ministério a mais de 40 anos;⁣
            </li>
            <li>
              Idealizador da Academia Apostólica, Centro Apostólico, Conexão
              Vertical e da Conferência de Líderes;⁣
            </li>
            <li>
              Foi Secretário da Cidade de Teutônia / RS - onde foi condecorado
              como Cidadão Emérito;⁣
            </li>
            <li>
              Escritor dos livros: O Poder das Alianças, Um Novo Projeto de Vida
              e Vale do Taquari (Existe uma Saída - Ela está em nós);⁣
            </li>
            <li>
              Possui Especialização em Governo Civil Judaico Cristão /
              Universidade das Nações - Medelin (CO)⁣
            </li>
            <li>
              É Doutor na Área de Liderança / Wagner Leadership Institute - EUA⁣
            </li>
            <li>Mestre em Engenharia / UFRGS⁣</li>
            <li>
              Pós Graduado em Engenharia da Qualidade e Produtividade / UFRGS⁣
            </li>
            <li>Bacharel em Administração / UNISC⁣</li>
            <li>Técnico Químico em Couros / SENAI⁣</li>
          </ul>
        </div>
      </div>
    </Biografia>

    <Footer />
  </>
);
export default SiglePage;
