import React from 'react';
import { Link } from 'react-router-dom';

import colors from '../../styles/colors';
import { Container } from './styles';

interface content {
  color?: string;
  url: string;
  children: JSX.Element;
}

const Button: React.FC<content> = ({
  url,
  color = colors.green_1,
  children,
}) => (
  <Container color={color}>
    <Link to={url}>{children}</Link>
  </Container>
);

export default Button;
