import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  background: linear-gradient(0.49deg, #000000 11.5%, rgb(255 255 255) 711.99%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  div {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 70vh;
      padding: 1rem;
    }

    img:nth-child(2n) {
      height: 12vh;
    }
  }
`;

export const Form = styled.div`
  form {
    width: 30vw;
    display: flex;
    flex-direction: column;

    input,
    select {
      height: 3rem;
      border-radius: 2px;
      border: none;
      margin: 0.5rem;
      padding: 0.5rem;
    }

    small {
      display: flex;
      align-items: center;

      a {
        color: ${colors.yellow_1};
        margin: 0 5px;
        text-decoration: underline;
        &:hover {
          color: ${colors.green_1};
          text-decoration: none;
        }
      }
    }

    @media only screen and (max-width: 785px) {
      width: 90vw;
    }
  }
`;
