import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import rwicon from '../../assets/rw-icon.png';

import { Head } from './styles';

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {!isNavExpanded ? <FaBars /> : <FaTimes />}
      </button>
      <div
        className={
          isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'
        }
      >
        <ul>
          <li>
            <HashLink
              to="/"
              title="Home"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Home
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#Sobre"
              title="Sobre"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Sobre
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#Idealizador"
              title="Projetos"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Projetos
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#Obras"
              title="Obras"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Obras
            </HashLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
const Header: React.FC = () => (
  <Head>
    <Link to="/">
      <img src={rwicon} alt="Ícone Ricardo Wagner" className="logo-rw" />
    </Link>
    <Navbar />
  </Head>
);
export default Header;
