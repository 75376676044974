import React from 'react';

import rwHome from '../../assets/ricardo-wagner.png';
import rwLogo from '../../assets/rw-logo.png';

import Footer from '../../components/Footer';
import Button from '../../components/Button';

import { Container, Form } from './styles';

const Lead: React.FC = () => (
  <>
    <Container>
      <div>
        <img src={rwHome} alt="" />
        <img src={rwLogo} alt="" />
      </div>
      <Form>
        <h1>Se inscreve e baixe o e-book</h1>
        <form action="">
          <input
            type="text"
            required
            placeholder="Nome"
            name="nome"
            id="nome"
          />
          <input
            type="text"
            required
            placeholder="E-mail"
            name="email"
            id="email"
          />
          <input
            type="text"
            placeholder="Telefone"
            required
            name="Telefone"
            id="Telefone"
          />
          <select name="ebook" required id="ebook">
            <option>Selecione uma opção</option>
            <option value="1">O PODER DAS ALIANÇAS</option>
            <option value="2">UM NOVO PROJETO DE VIDA</option>
            <option value="3">VALE DO TAQUARI</option>
          </select>
          <small>
            <input
              type="checkbox"
              required
              name="termos-privacidade"
              id="termos-privacidade"
            />{' '}
            Concordo que li os
            <a href="" title="Termos" target="_blank" className="termos">
              termos
            </a>
            e aceito.
          </small>

          <Button url="lead">
            <div>
              <p>Obter e-book</p>
            </div>
          </Button>
        </form>
      </Form>
    </Container>
    <Footer />
  </>
);
export default Lead;
