import { css } from 'styled-components';
import { shade } from 'polished';
import colors from './colors';

export const buttonStyles = css<{ color: any }>`
  width: 100%;
  margin: 10px auto;
  padding: 10px;

  text-decoration: none;

  background: ${props => props.color};
  color: ${colors.white};
  box-shadow: 3px 3px 8px rgb(0 0 0 / 5%);
  text-shadow: 2px 1px 3px rgb(0 0 0 / 4%);
  transition: background-color 0.3s;

  border: none;
  border-bottom: 4px solid ${props => shade(0.2, props.color)};
  border-radius: 5px;

  letter-spacing: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => shade(0.2, props.color)};
    border-bottom-color: ${props => props.color};
    transition: background-color 0.5s;
  }

  svg {
    float: left;
    padding: 0 5px;
    font-weight: bold;
    width: auto;
  }

  p {
    font-weight: 600;
  }

  small {
    font-weight: 300;
    font-size: 10px;
  }

  svg {
    font-size: 1.7rem;
  }
`;
