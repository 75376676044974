import React from 'react';

import { Routes, Route, HashRouter } from 'react-router-dom';
import GlobalsStyles from './styles/global';

import SinglePage from './pages/SiglePage';
import Lead from './pages/Lead';

const App: React.FC = () => (
  <>
    <HashRouter>
      <Routes>
        <Route path="/" element={<SinglePage />} />
        <Route path="/lead" element={<Lead />} />
      </Routes>
    </HashRouter>
    <GlobalsStyles />
  </>
);

export default App;
